.topBtnSty {
  position: absolute;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.topTitle {
  color: var(--text-color);
  font-weight: bold;
  font-size: 18px;
}

.infoIconSty {
  color: #faad14;
  width: 14px;
  height: 14px;
  margin-left: 6px;
}

.topTags {
  margin-left: 60px;

  .hint {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  :global {
    .ant-space-item {
      line-height: 16px;
    }
  }
}


.canvasWapper {
  width: 100%;
  position: relative;

  .fullBtnSty {
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 10px;
  }

  .alarmWrapper {
    position: absolute;
    z-index: 1;
    top: 16px;
    left: 16px;
    width: 280px;
    height: 265px;
    background: rgba(45,45,45,0.9);
    border-radius: 2px;
  }
}

.helloworldApp {
  display: flex;
  width: 100%;
  font-family: sans-serif;

  .appContent {
    flex: 1;
    min-height: calc(100vh - 158px);
    border-radius: 5px;
    body {
      min-width: 40px;
    }
  }
}



.antLine :local {
  animation: antLine 30s infinite linear;
}

@keyframes antLine {
  to {
    stroke-dashoffset: -1000;
  }
}