@import '../../common.module.scss';
// 光伏站
.customPVaReactNode {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 160px;
  height: 230px;
  color: #fff;
  background-color: transparent;
  border-radius: 6px;

  .vline {
    position: absolute;
    top: 0px;
    left: 80px;
    width: 1px;
    height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .content {
    position: absolute;
    top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    // height: 116px;
    // min-height: 116px;
    min-height: 102px;
    // background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid rgba(22, 221, 142, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    // z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }

    &:hover {
      outline: 2px solid #ffffff;
    }
  }

  .cardSty {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    .infoIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      color: #FAAD14
    }
    .tagSty {
      position: absolute;
      top: 8px;
      left: 8px;
      :global {
        .ant-tag-green {
          color: rgba(250, 173, 20, 1);
          background: rgba(250, 173, 20, 0.1);
          border-radius: 2px;
          border: 1px solid rgba(250, 173, 20, 0.5);
        }
      }
    }
    .itemControlobjContentSty {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
      text-align: center;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .pvaStitleSty {
      height: 20px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
    }
  }
}


