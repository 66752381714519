@import '../GridAccessPointDrawer/index.module.scss';

.iconWrapper {
  .iconBox {
    min-width: 160px !important;
    min-height: 82px !important;

    &::after {
      background: #abd335 !important;
    }

    .icon {
      background-image: url(./charger.png) !important;
    }
  }
}