.wrap {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 128px);
}

.topCard {
  height: 64px;
  padding: 0 23px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  background: #2d2d2d;

  .itemWrap {
    display: flex;
    align-items: center;
  }

  .divider {
    margin: 0 16px;
    height: 32px;
    width: 1px;
    background: rgba(255, 255, 255, 0.1);
  }

  .label {
    color: var(--text-color-secondary);
  }

  .basIcon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
}

.topActionBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .tip {
    color: var(--warning-color);
  }
}

.mainContent {
  flex: 1;
  display: flex;
  gap: 16px;
  height: 0;

  > div {
    flex: 1;
    min-width: 0;
    overflow: hidden;
  }
}

.primaryColor {
  color: var(--primary-color);
}

.warningColor {
  color: var(--warning-color);
}

.successColor {
  color: var(--success-color) !important;
}
