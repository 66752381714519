.wrapper {
  min-height: calc(100vh - 130px) !important;
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: 0;

  :global {
    .mx-tabs {
      background-color: var(--component-background);
      padding: 0 24px;
    }
  }
}
