
.dcBusBarWrapper {
  position: relative;
  display: flex;
  height: 30px;
  width: 1040px;
  opacity: 1;

  .generatrixEachNode {
    width: 900px;
    height: 6px;
    border: 2px solid #4a90e2;
    background:  #fff ;
    position: relative;
  }

  .generatrixValueSty {
    width: 140px;
    position: absolute;
    top: -10px;
    left: 910px;
  }

  .bline1 {
    position: absolute;
    left: 80px;
    top: 6px;
    width: 1px;
    height: 24px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
  .bline2 {
    position: absolute;
    left:450px;
    top: 6px;
    width: 1px;
    height: 24px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
  .bline3 {
    position: absolute;
    right:390px;
    top: 6px;
    width: 1px;
    height: 24px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
}
