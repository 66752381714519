.page_box {
  padding: 0;
}
.topBtnSty {
  position: absolute;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
}

.topTitle {
  color: var(--text-color);
  font-weight: bold;
  font-size: 18px;
}

.infoIconSty {
  color: #faad14;
  width: 14px;
  height: 14px;
  margin-left: 6px;
}

.topTags {
  margin-left: 60px;
  .hint {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  :global {
    .ant-space-item {
      line-height: 16px;
    }
  }
}

.btnAndCanvasBgSty {
  width: 100%;
  position: relative;
    // background-image: url('./imgs/bg.gif');
    // background-color: #332d2d30;
    // opacity: 0.3;
}

.canvasWapper {
  margin-top: 50px;
  // border: 1px dashed rgba(74, 144, 226, 1);
}

.helloworldApp {
  display: flex;
  width: 100%;
  margin-top: -50px;
  font-family: sans-serif;

  .appContent {
    flex: 1;
    min-height: calc(100vh - 198px);
    // margin-right: 8px;
    // margin-left: 8px;
    border-radius: 5px;
    // box-shadow: 0 12px 5px -10px rgb(0 0 0 / 10%), 0 0 4px 0 rgb(0 0 0 / 10%);
    body {
      min-width: 40px;
    }
    
  }
}



.openBottomMemuSty {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color:#222223;
  color: rgb(var(--base-text-color));
  // color: #ffffff85;
  color: rgba(255, 255, 255, 0.85);;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  &:hover {
    background-color:var(--primary-color)
  }
}
.drawSty {
  :global{
    .ant-drawer-body{
      padding-bottom: 6px;
      background-color: #222223;
    }
  }
}

.drawShowBtnSty {
  position: absolute;
  left: 50%;
  top: -20px;
  line-height: 28px;
  transform: translateX(-50%);
  width: 160px;
  height: 28px;
  background-color: #222223;
  // color: rgb(var(--base-text-color));
  color: rgba(255, 255, 255, 0.85);;
  text-align: center;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  &:hover {
    background-color:var(--primary-color)
  }
}

.linkNodeBtn{
  width: 160px;
  height: 28px;
  background: linear-gradient(90deg, rgba(133,78,202,0.3) 0%, rgba(133,78,202,0.5) 100%);
  border-radius: 2px;
  border: 1px solid rgba(255,255,255,0.2);
  cursor: pointer;
  padding: 2px 8px;
  margin-right: 8px;
  &:hover {
    border: 1px solid rgba(255,255,255,0.65);
  }
  
}

.quickJumpBtn{
  width: 160px;
  height: 28px;
  background: linear-gradient(90deg, rgba(74,144,226,0.3) 0%, rgba(74,144,226,0.5) 100%);
  border-radius: 2px;
  border: 1px solid rgba(255,255,255,0.2);
  cursor: pointer;
  padding: 2px 8px;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border: 1px solid rgba(255,255,255,0.65);
  }
}

.memuTipSty{
  font-size: 12px;
  font-weight: 400;
  color: rgba(255,255,255,0.65);
  line-height: 20px;
  display: flex;
  margin-bottom: 16px;
  .purpleBtnSty{
    width: 64px;
    height: 20px;
    background: #854ECA40;
    border-radius: 2px;
    color: rgba(255,255,255,0.85);
    text-align: center;
    line-height: 20px;
  }
  .blueBtnSty{
    width: 64px;
    height: 20px;
    background: #4A90E240;
    border-radius: 2px;
    color: rgba(255,255,255,0.85);
    text-align: center;
    line-height: 20px;
  }
}

.antLine :local {
  animation: antLine 30s infinite linear;
}

@keyframes antLine {
  to {
    stroke-dashoffset: -1000;
  }
}

