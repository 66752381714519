@import '../GridAccessPointDrawer/index.module.scss';

.iconWrapper {
  min-height: 82px !important;
  padding: 14px 0 !important;
  overflow: hidden !important;

  .iconBox {
    padding-left: 8px !important;
    padding-right: 8px !important;
    min-width: 160px !important;
    min-height: 82px !important;

    &::after {
      background: #abd335 !important;
    }

    .icon {
      background-image: url(./load.png) !important;
    }

    .dashed {
      width: 100%;
      height: 1px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      margin-top: 4px;
      margin-bottom: 8px;
    }

    .controlObject {
      display: flex;
      flex-direction: column;
      width: 100%;

      .controlObjectIcon {
        max-width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        // background: rgba(255, 255, 255, 0.1);
        background: #2D2D2D;
        border-radius: 2px;
        margin-bottom: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }
}