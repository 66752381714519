@import '../PowerGrid/index.module.scss';

.checkBox {
  flex-direction: column;

  :global {
    .ant-checkbox-wrapper {
      margin-inline-start: 0px;
    }
  }
}

.iconWrapper {
  min-height: 142px !important;
  padding: 8px 0 !important;

  .iconBox {
    position: relative;
    padding: 4px 8px !important;
    min-width: 160px !important;
    min-height: 110px !important;

    .icon {
      margin-top: 8px;
      background-image: url(./PV@2x.png) !important;
    }

    .part {
      position: absolute;
      top: 8px;
      left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 22px;
      background: rgba(250, 173, 20, 0.1);
      border-radius: 2px;
      border: 1px solid rgba(250, 173, 20, 0.5);
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #faad14;
    }

    .parallelBranch {
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
    }

    .dashed {
      width: 100%;
      height: 1px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      margin-top: 4px;
      margin-bottom: 8px;
    }

    .controlObject {
      width: 100%;
      display: flex;
      flex-direction: column;

      .controlObjectIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: max-content;
        margin-bottom: 4px;
        padding: 0 8px;
        height: 22px;
        // background: rgba(255, 255, 255, 0.1);
        background: #2D2D2D;
        border-radius: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }
}
