@import '../GridAccessPointDrawer/index.module.scss';


.iconWrapper {
  min-width: 300px !important;
  min-height: 72px !important;

  .iconBox {
    flex-direction: row !important;
    min-width: 160px !important;
    min-height: 40px !important;
    border-radius: 2px !important;

    &::after {
      width: 3px !important;
      height: 100% !important;
      left: 0 !important;
      top: 0 !important;
      background: #ff9247 !important;
      border-radius: 2px 0px 0px 2px !important;
    }

    .icon {
      background-image: url(./gateway.png) !important;
      background-size: 24px 24px !important;
    }

    .iconText {
      min-height: 22px !important;
      font-size: 14px !important;
      line-height: 22px !important;
      margin-right: 10px !important;
    }
  }
}