@import '../../common.module.scss';

// 自定义水平
.customMetersReactNode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 80px;
  color: #fff;

  .vline {
    position: absolute;
    top: 0px;
    left: 8px;
    width: 1px;
    height: 100%;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .hline {
    position: absolute;
    top: 40px;
    left: 9px;
    width: 30px;
    height: 1px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardMeterSty_wrapper {
    position: absolute;
    left: 39px;
    top: 20px;
    width: 160px;
    // height: 40px;
    // min-height: 52px;
    min-height: 38px;
    display: flex;
    flex-direction: column;
    
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 3px solid #8CA9DC;
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    // z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }

    &:hover {
      outline: 2px solid #ffffff;
    }
    .cardMeterSty {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        height: 38px;
        display: flex;
        align-items: center;
        width: 100%;
        .img_sty {
          width: 24px;
          height: 24px;
          margin-left: 8px
        }
        .infoIcon {
          margin-right: 8px;
          color: #FAAD14
        }
        
      }
      .itemControlobjContentSty {
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

}
