.alarm_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  gap: 16px;
  .alarm_wrapper_outer {
    // flex: 2;
    overflow: hidden;
    max-height: 50%;
    
  }
  .alarm_wrapper_box {
    height: 100%;
    display: flex;
    flex-direction: column;
    .alarm_wrapper_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
      .alarm_wrapper_header_title {
        font-weight: 500;
        font-size: 14px;
        color: rgba(255,255,255,0.85);
        line-height: 22px;
      }
      .alarm_wrapper_header_count {
        color: rgba(255,255,255,0.65);
        line-height: 22px;
        .number {
          color: #E64242;
        }
      }
    }
    
    .alarm_wrapper_content {
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ffffff40;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      .item_sty {
        height: 66px;
        margin-bottom: 8px;
        padding: 8px 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: #2D2D2D;
        border-radius: 2px;
        .item_sty_top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .alarmName {
            font-size: 12px;
            color: rgba(255,255,255,0.85);
            line-height: 20px;
          }
        }
        .item_sty_bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
            color: rgba(255,255,255,0.65);
          .divide_line {
            width: 1px;
            height: 12px;
            background: rgba(255,255,255,0.2);
            margin: 0 8px;
          }
          .device_box {
            flex: 1;
            margin-right: 8px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .alarmTime {
            
            line-height: 20px;
          }
        }
      }
    }
  }
  
}
.dispath_box {
  // flex: 3;
  max-height: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .dispath_search {
    padding-bottom: 10px
  }
  .dispath_header {
    display: flex;
    color: rgba(255,255,255,0.85);
    justify-content: space-between;
    .header_left {
      font-weight: 500;
    }
  }
  .dispath_table_outer {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 10px;
    .dispath_table {
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ffffff40;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      :global {
        .ant-table-body,.ant-table-content {
          scrollbar-width: auto;
          scrollbar-color: auto;
          &::-webkit-scrollbar {
            height: 8px;
          }
          &::-webkit-scrollbar-thumb {
            background: #ffffff40;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-track {
            border-radius: 10px;
          }
        }
      }
    }
  }
  .page_box {
     
      .page_box_inner {
        padding-top: 0;
      }
    
  }
  
}
.confirm_box {
  .confirm_title {
    margin-bottom: 8px;
  }
  .send_text_box {
    word-break: break-all;
    .tip_red {
      color: #CB3E3E;
      padding: 0 5px;
    }
  }
  .confirm_content {
    background: rgba(255,255,255,0.05);
    color: rgba(255,255,255,0.65);
    border-radius: 2px;
    border: 1px solid rgba(255,255,255,0.1);
    padding: 8px 16px;
    .target_value {
      color:rgba(255,255,255,0.85)
    }
  }
  .divider {
    height: 1px;
    background: rgba(255,255,255,0.1);
    margin: 8px 0;
  }
}
