$primaryColor: var(--primary-color);
$base-text-color: var(--base-text-color);

.title {
  color: var(--heading-color);
  font-weight: 500;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  margin: 0 30px 0 15px;
  line-height: 30px;
}
.tag_box {
  // display: flex;
  // align-items: flex-end;
  // flex-wrap: wrap;
  width: 100%;
  :global {
    .ant-tag {
      display: block;
      margin-bottom: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.operationArea {
  margin-top: 4px;
  margin-bottom: 12px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}

.list {
  .name {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
    margin: 8px 0;
  }
  .tag {
    height: 24px;
    border: 1px solid #4a90e2;
    color: #4a90e2;
    padding: 0px 6px;
  }
  .content {
    position: relative;
    display: flex;
    overflow: hidden;
    padding: 30px 16px;
    border: 1px solid transparent;
    border-bottom: 1px solid rgba(var(--base-text-color), 0.15);
    margin-top: -1px;
    margin-bottom: 16px;
    cursor: pointer;
    background: rgba(74, 144, 226, 0.1);

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 100%;
      background: $primaryColor;
      left: 0;
      top: 0;
    }

    .leftContent {
      min-width: 200px;
    }
    .rightContent {
      margin-bottom: 15px;

      .dashed {
        width: 100%;
        height: 1px;
        border: 1px dashed rgba(255, 255, 255, 0.1);
        margin: 25px 0;
      }
    }
    .badge {
      margin-right: 10px;
      white-space: nowrap;
      color: #00adff;
      position: relative;
      padding: 1px 10px;
      margin-bottom: 4px;
      display: inline-block;
      .bac {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(74, 144, 226, 0.8);
        opacity: 0.2;
        top: 0;
        left: 0;
        border-radius: 11px;
      }
    }
    .word_wrap {
      word-wrap: break-word;
    }
    .actionContent {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 100%;
      text-align: right;
      .action {
        margin-left: 30px;
        position: relative;
        display: inline-block;
        text-align: left;
        .btn {
          padding-left: 0;
          padding-right: 0;
          margin-right: 10px;
        }
        .gray {
          color: rgba($base-text-color, 0.5);
        }
      }
    }
    :global {
      .ant-descriptions-row > th {
        padding-bottom: 8px;
      }
      .ant-descriptions-row > td {
        padding-bottom: 0;
      }
    }
  }
  .content:hover {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
    border-radius: 2px;
  }
}
