// :global {
  // .ant-dropdown-menu-submenu .ant-dropdown-menu {
  //   background-color: #1f1f1f;
  // }

  // .ant-dropdown-menu-submenu {
  //   .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-active .ant-dropdown-menu-item-only-child) {
  //     color: rgba(var(--base-text-color), 0.8);
  //     &:hover {
  //       // background-color: var(--primary-color);
  //       background-color: rgba(255, 255, 255, 0.05);
  //     }
  //   }
  // }

  // .ant-dropdown-menu-submenu-arrow-icon {
  //   color: rgba(var(--base-text-color), 0.8) !important;
  //   &:hover {
  //     color: rgb(var(--base-text-color));
  //   }
  // }

  // .ant-cascader-dropdown {
  //   background-color: #1f1f1f;
  //   .ant-cascader-menus {
  //     background-color: #1f1f1f;
  //     .ant-cascader-menu {
  //       background-color: #1f1f1f;
  //       height: 240px;
  //       color: rgba(255, 255, 255, 0.85);
  //       .ant-cascader-menu-item-expand-icon{
  //         color: rgba(255, 255, 255, 0.85);
  //       }
  //     }
  //   }
  // }

// }

@keyframes animated-border {
  0% {
    box-shadow: 0 0 0 0 rgba(255,255,255,0.4);
  }
  100% {
     box-shadow: 0 0 0 10px rgba(255,255,255,0);
  }
}

.drapDownCascaderSty {
  background-color: #1f1f1f;
  :global {
    
      .ant-cascader-menus {
        background-color: #1f1f1f;
        .ant-cascader-menu {
          background-color: #1f1f1f;
          height: 240px;
          color: rgba(255, 255, 255, 0.85);
          .ant-cascader-menu-item {
            &:hover {
              color: rgb(var(--base-text-color));
              background-color: rgb(42, 42, 42);
            }
            .ant-cascader-menu-item-content{
              color: rgb(var(--base-text-color));
            }
          }

          .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
              color: rgb(var(--base-text-color));
              background-color: rgb(42, 42, 42);
            &:hover {
              color: rgb(var(--base-text-color));
              background-color: rgb(42, 42, 42);
            }
          }


          .ant-cascader-menu-item-expand-icon{
            color: rgba(255, 255, 255, 0.85);
          }
        }
      }
    
  }
}

.drapDownStyle {
  :global {
    background-color: rgba(74,144,226,0.16);
    // min-width: 226px !important;
    background-color: #1f1f1f;
    // padding: 6px 0px 8px 4px;
    .ant-cascader {
      // background-color: rgba(74, 144, 226, 0.16);
      .ant-select-selector{
        border: 1px solid #ffffff70;
        background-color: rgba(74, 144, 226, 0.16);
        .ant-select-selection-search{
          color: #fff;
        }
        .ant-select-selection-item{
          color: #fff;
        }
      }
      .ant-select-arrow {
        color: #ffffff80;
      }
      .ant-select-selection-placeholder{
        color: #ffffff80;
      }
    }


    .ant-dropdown-menu {
      background-color: #1f1f1f;
      .ant-dropdown-menu-submenu-title {
        &:hover {
          // background-color: var(--primary-color);
          background-color: rgba(255, 255, 255, 0.05);
        }
      }

      .ant-dropdown-menu-title-content {
        color: rgba(var(--base-text-color), 0.8);
        &:hover {
          color: rgb(var(--base-text-color));
        }
      }

      .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-selected) {
        height: 32px;
        border-radius: 2px;
        color: rgba(var(--base-text-color), 0.8);
        &:hover {
          color: rgb(var(--base-text-color));
          background-color: var(--primary-color);
        }
      }
      .ant-dropdown-menu-item-selected {
        height: 32px;
        background: rgba(74, 144, 226, 0.16);
        color: var(--primary-color);
        &:hover {
          color: rgb(var(--base-text-color));
          background-color: var(--primary-color);
        }
      }
      .ant-dropdown-menu-item-disabled {
        color: #ffffff40 !important;
      }
    }
  }
}

.popconfirmSty {
  :global {
    .ant-popover-inner {
      background-color: #1f1f1f;
      background-clip: padding-box;
      border-radius: 2px;
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
      padding: 12px;
    }
    .ant-popover-inner-content {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-popconfirm .ant-popconfirm-inner-content {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-popconfirm .ant-popconfirm-message {
      position: relative;
      margin-bottom: 8px;
      color: rgba(255, 255, 255, 0.85);
      font-size: 14px;
      display: flex;
      flex-wrap: nowrap;
      align-items: start;
    }
    .ant-btn-default {
      color: #fff;
      background-color: #232324;
      border-color: rgba(0, 173, 255, 0.5);
      box-shadow: 0 2px 0 rgba(255, 255, 255, 0.04);
    }
    .ant-btn-primary {
      color: #fff;
      background-color: #427dc3;
      box-shadow: 0 2px 0 rgba(0, 19, 47, 0.37);
    }
    .ant-popover-arrow:before {
      background-color: #1f1f1f;
    }
  }
}

@keyframes ant-line {
  to {
    stroke-dashoffset: -1000;
  }
}

:global {
  @keyframes ant-line {
    to {
      stroke-dashoffset: -1000;
    }
  }
}
