.tabs_content_sticky_footer {
  position: sticky;
  left: 0;
  bottom: 0px;
  padding: 32px 0;
  width: 100%;
  z-index: 999;
}
.table_inner_form_item {

    margin-bottom: 0!important;
  
}