.wrapper {
  margin-bottom: 15px;
}

.tips {
  display: flex;
  .blue {
    padding: 0 5px;
    color: #4a90e2;
  }
  .info {
    padding-top: 2px;
    padding-left: 30px;
    font-size: 12px;
  }
}
