.btns {
  position: absolute;
  right: 0px;
  top: 13px;
}

.tabs {
  overflow: visible;
  :global {
    .ant-tabs-nav {
      padding-left: 10px;
    }
  }
}


.top {
  .topTags {
    position: absolute;
    left: 260px;
    top: 50%;
    transform: translateY(-50%);
    .hint {
      display: inline-block;
      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :global {
      .ant-space-item {
        line-height: 16px;
        height: 16px;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    position: relative;
    color: var(--text-color);
    font-weight: bold;
    font-size: 18px;
    padding-left: 10px;
    margin: 0;
  }
  .title:before {
    content: '';
    width: 4px;
    height: 20px;
    background-color: var(--primary-color);
    position: absolute;
    left: 0;
    top: 4px;
  }
}
