@import '../../common.module.scss';
// 储能站
.customBsaReactNode {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 160px;
  // width: 161px;
  height: 230px;
  color: #fff;

  .vline {
    position: absolute;
    top: 0px;
    left: 80px;
    width: 1px;
    height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardSty {
    position: absolute;
    top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    // height: 197px;
    min-height: 97px;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid rgba(206, 144, 209, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;
    &:hover {
      outline: 2px solid #ffffff;
    }

    .itemControlobjContentSty {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
      text-align: center;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .tagSty {
      position: absolute;
      top: 8px;
      left: 8px;
      :global {
        .ant-tag-green {
          color: rgba(250, 173, 20, 1);
          background: rgba(250, 173, 20, 0.1);
          border-radius: 2px;
          border: 1px solid rgba(250, 173, 20, 0.5);
        }
      }
    }
    .pvaNameSty {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
    }
    .pvaStitleSty {
      height: 20px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
    }
    .FcsPcsInfo {
      width: 100%;
      padding: 0px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .pcsInfoSty {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
      }
    }
    .controlObjSty {
      // height: 90px;
      max-height: 90px;
      width: 150px;
      padding-left: 4px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #ffffff40;
        border-radius: 10px;
      }
      .itemControlobjSty {
        height: 22px;
        // background: rgba(255, 255, 255, 0.1);
        background: #2D2D2D;
        border-radius: 2px;
        margin-bottom: 6px;
        .itemControlobjContentSty {
          width: 150px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .dividerSty {
      margin: 6px 0 10px 0px;
      width: 144px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .addCircle {
    position: absolute;
    top: 0px;
    left: 73px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .deleteIcon {
    position: absolute;
    top: 36px;
    right: 6px;
    cursor: pointer;
    z-index: 99;
    color: rgba(255, 255, 255, 0.65);
    &:hover {
      color: #ffffff;
    }
  }
}
