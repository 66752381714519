.wrapper {
  height: calc(100vh - 130px) !important;
  padding: 20px;
}

.tab_box {
  :global {
    .ant-tabs-tab-btn:focus:not(:focus-visible) {
      color: inherit!important;
    }
  }
}
