.wrap {
  padding: 20px;
}

.wrapper {
  margin-bottom: 15px;
}

.compute_box {
  width: calc(50% - 136px) ;
  background: var(--mx-base-bg-color);
  border-radius: 2px;
  border: 1px solid var(--mx-light-border-color);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 16px;
  box-sizing: border-box;
  color: var(--mx-text-secondary-color);
  .throug_line {
    height: 1px;
    background: var(--mx-disabled-bg-color);
  }
  .compute_value {
    display: flex;
    padding: 10px 0;
    .text {
      padding-right: 5px;
    }
    .value {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .compute_formula {
    padding: 10px 0;
  }
}

.select_btn {
  color: var(--mx-primary-color);
  cursor: pointer;
}
.disable_btn {
  color: var(--mx-text-secondary-color);
  cursor: auto;
}
.action_line {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .click_text {
    color: var(--mx-primary-color);
    cursor: pointer;
  }
}
