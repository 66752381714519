
.publishTitle{
  height: 22px;
  font-weight: 500;
  color: rgba(255,255,255,0.85);
  line-height: 22px;
  margin-bottom: 16px;
}

.titleSty{
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255,255,255,0.85);
  line-height: 24px;
  margin-right: 4px;
}
.timeSty{
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255,255,255,0.65);
  line-height: 22px;
  margin: 8px 0px;
}
.custNameSty{
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255,255,255,0.65);
  line-height: 22px;
}

.timeLineSty{
  :global{
    .ant-timeline .ant-timeline-item{
      .ant-timeline-item-tail{
        border-inline-start: 1px dashed #FFFFFF85;
      }
      .ant-timeline-item-content{
        inset-block-start: -4px;
      }
    }

  }

}