
@import '../../common.module.scss';
// 储能站
.customBsaReactNode {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 160px;
  height: 242px;
  color: #fff;
  opacity: 1;

  .cardSty {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 242px;
    border-radius: 2px;
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid rgba(206, 144, 209, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }
    &:hover {
      outline: 2px solid #ffffff;
    }
    .topContentSty{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      width: 100%;
    }

    .dividerSty {
      margin: 6px 0 10px 0px;
      width: 144px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    .bottomContentSty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .itemProp {
        width: 144px;
        height: 32px;
        background: rgba(255,255,255,0.1);
        border-radius: 2px;
        margin: 0px 8px 4px 8px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 8px;
        .itemPropName{
          
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(255,255,255,0.65);
          line-height: 22px;
        }
        .itemPropValue {
          
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(255,255,255,0.85);
          line-height: 22px;
        }
      }
      .itemSocProp {
        width: 144px;
        height: 40px;
        background: rgba(255,255,255,0.1);
        border-radius: 2px;
        margin: 0px 8px 4px 8px;
        display: flex;
        flex-direction: column;
        padding: 7px 7px 0px 7px;
        .itemSocNameValueProp {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .itemPropName{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: rgba(255,255,255,0.65);
            line-height: 22px;
          }
          .itemPropValue {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: rgba(255,255,255,0.85);
            line-height: 22px;
          }
        }
        .itemSocPercentSty {
          margin-top: -8px;
          
        }
      }
    }
  }
}
