@import '../../common.module.scss';
// 备用电源
.customBackUppowerReactNode {
  height: 100%;
  width: 100%;
  color: #fff;


  .vline {
    position: absolute;
    top: 0px;
    left: 80px;
    width: 1px;
    height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .content {
    position: absolute;
    top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    // min-height: 94px;
    min-height: 82px;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid rgba(22, 221, 142, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    z-index: 0;
    overflow: hidden;
    // will-change: transform;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }

    &:hover {
      outline: 2px solid #ffffff;
    }

  }


  .cardSty {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    .top_card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .infoIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      color: #FAAD14
    }
    .titleSty {
      text-align: center;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
