.topContentSty {
  width: 100%;
  padding: 12px 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .alarmInfoTitle {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(255,255,255,0.85);
    line-height: 22px;
    padding-left: 4px;
  }

  .alarmNumberTitle {
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: rgba(255,255,255,0.65);
    line-height: 20px;
  }

  .alarmNumber{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(255,255,255,0.85);
    line-height: 22px;
    padding-left: 4px;
  }
}

.infoContent {
  padding: 0px 12px 12px 12px;
  height: 265px;
  // :global {
  //   .swiper-wrapper {
  //     height: 200px;
  //   }
  //   .swiper-container-free-mode>.swiper-wrapper {
  //     -webkit-transition-timing-function: linear;
  //     /*之前是ease-out*/
  //     -moz-transition-timing-function: linear;
  //     -ms-transition-timing-function: linear;
  //     -o-transition-timing-function: linear;
  //     transition-timing-function: linear;
  //     margin: 0 auto;
  //   }
  // }
  .itemSty {
    width: 256px;
    height: 64px;
    background: rgba(255,255,255,0.05);
    border-radius: 2px;
    border: 1px solid rgba(255,255,255,0.1);
    margin-bottom: 8px;
    .itemTopSty {
      display: flex;
      padding: 9px 8px 0px 8px;
      justify-content: space-between;
      align-items: center;
      .timeSty {
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(255,255,255,0.65);
        line-height: 20px;
      }
    }
    .alarmDetailInfoSty{
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(255,255,255,0.85);
      line-height: 22px;
      padding: 0px 8px;
    }
  }
}