.wrap {
  padding: 20px;
}

.pageContent {
  padding: 0 32px;
}

.diffValue {
  color: var(--warning-color);
}

.detailDialogContent {
}
