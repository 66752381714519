@import '../GridAccessPointDrawer/index.module.scss';

.iconWrapper {
  min-width: 296px !important;
  min-height: 144px !important;

  .iconBox {
    flex-direction: column !important;
    min-width: 160px !important;
    min-height: 104px !important;

    &::after {
      width: 100% !important;
      height: 2px !important;
      left: 0 !important;
      top: 0 !important;
      background: #ffcb47 !important;
      border-radius: 2px 0px 0px 2px !important;
    }

    .icon {
      background-image: url(./universalSwitch.png) !important;
      background-size: 40px 40px !important;
    }
  }
}