@import '../GridAccessPointDrawer/index.module.scss';

.iconWrapper {
  min-height: 150px !important;
  padding: 16px 0 !important;

  .iconBox {
    position: relative;
    padding: 8px !important;
    min-width: 160px !important;

    &::after {
      background: #ce90d1 !important;
    }

    .part {
      position: absolute;
      top: 8px;
      left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 22px;
      background: rgba(250, 173, 20, 0.1);
      border-radius: 2px;
      border: 1px solid rgba(250, 173, 20, 0.5);
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #faad14;
    }

    .icon {
      margin-top: 8px;
      background-image: url(./bsa.png) !important;
    }

    .fcsAndpcs {
      display: flex;
      width: 100%;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      .from {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #faad14;
        margin-right: 4px;
      }

      .text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .dashed {
      width: 100%;
      height: 1px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      margin-top: 4px;
      margin-bottom: 8px;
    }

    .controlObject {
      width: 100%;
      display: flex;
      flex-direction: column;

      .controlObjectIcon {
        max-width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        margin-bottom: 4px;
        height: 22px;
        // background: rgba(255, 255, 255, 0.1);
        background: #2D2D2D;
        border-radius: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}