@import '../../common.module.scss';
// 备用电源
.customBackUppowerReactNode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  color: #fff;

  .deleteIcon {
    position: absolute;
    top: 36px;
    right: 5px;
    cursor: pointer;
    z-index: 99;
    color: rgba(255, 255, 255, 0.65);
    &:hover {
      color: #ffffff;
    }
  }

  .vline {
    position: absolute;
    top: 0px;
    left: 80px;
    width: 1px;
    height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .addCircle {
    position: absolute;
    left: 73px;
    top: 0px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .cardSty {
    position: absolute;
    top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    min-height: 82px;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid rgba(22, 221, 142, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;
    &:hover {
      outline: 2px solid #ffffff;
    }

    .titleSty {
      text-align: center;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .itemControlobjSty {
      height: 22px;
      // background: rgba(255, 255, 255, 0.1);
      background: #2D2D2D;
      border-radius: 2px;
      margin-bottom: 6px;
      .itemControlobjContentSty {
        padding-left: 8px;
        width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .dividerSty {
      margin: 6px 0 10px 0px;
      width: 144px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
