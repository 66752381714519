@import '../PowerGrid/index.module.scss';

.iconWrapper {
  min-height: 76px !important;

  .iconBox {
    flex-direction: row !important;
    background: none !important;
    border: none !important;
    min-height: 50px !important;

    &::after {
      background: transparent !important;
    }

    .icon {
      margin-right: 4px;
      width: 245px !important;
      height: 4px !important;
      background: #4a90e2 !important;
      background-image: url(./generatix.png) !important;
      background-size: 245px 4px !important;
    }

    .iconText {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      width: auto !important;
      height: auto !important;

      .voltageLevel {
        color: #faad14;
      }
    }
  }
}
