.wrap {
  padding: 20px;
}

.mainContent {
  padding: 0 32px;
}

.relateDateModalContent {
  .monthRow {
    display: flex;
    margin-bottom: 24px;

    .monthLabel {
      color: var(--primary-color);
    }

    .dateListWrap {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .dateItem {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 106px;
        height: 24px;
        background: rgba(74, 144, 226, 0.19);
        border-radius: 1px;
        border: 1px solid #4a90e2;
        color: var(--text-color);
      }
    }
  }
}
