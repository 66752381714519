$primaryColor: var(--mx-primary-color);

.App {
  height: 100%;
}

.sticky-footer {
  position: sticky;
  left: 0;
  padding-left: 28px;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}

// table 操作栏按钮 后续统一使用该class
.action-buttons {
  .ant-btn-link {
    padding: 0;
    line-height: 30px;
    height: 30px;
    font-size: 12px;
  }
  .ant-btn {
    font-size: 12px;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 2px;
  }
}

// 临时解决列表操作栏的样式，后续使用上面的class
.ant-table-cell {
  .ant-space {
    .ant-btn-link {
      padding: 0;
      line-height: var(--mx-line-height-sm);
      height: var(--mx-line-height-sm);
      font-size: 12px;
    }
    .ant-btn {
      font-size: 12px;
    }
    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin-left: 2px;
    }
  }
  .ant-badge {
    .ant-badge-status-text {
      font-size: 12px;
    }
  }
}

.ant-form-item__longLabel {
  label {
    font-size: 12px;
  }
}

.detail-col-gap {
  margin-bottom: 35px;
}

// .ant-input-number-disabled {
//   border-color: rgba(118, 118, 118, 0.3);
// }

// .ant-input-number-disabled .ant-input-number-input,
// .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
// .ant-input-affix-wrapper-disabled {
//   color: #00000040;
//   background: #f5f5f5;
// }

.ant-empty-image {
  height: 88px;
}

.loading-modal {
  .loading-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading-modal-icon {
      font-size: 50px;
      color: $primaryColor;
    }
    .loading-modal-txt {
      padding-bottom: 10px;
    }
  }
  .ant-modal-confirm-btns {
    display: none;
  }
}

.swiper-button-prev {
  position: absolute;
  left: 0px;
  top: 48px;
  z-index: 99;
  cursor: pointer;
  svg {
    color: rgba(255, 255, 255, 0.75);
  }
}

.swiper-button-next {
  position: absolute;
  right: 0px;
  top: 48px;
  z-index: 99;
  cursor: pointer;
  svg {
    color: rgba(255, 255, 255, 0.75);
  }
}
.page_wrapper {
  padding: 20px!important;
}
.modal-form-content {
  padding: 32px 72px 8px 72px !important;
}

::-webkit-scrollbar {
  border-radius: 0;
  background: var(--mx-scrollbar-bg);
  width: 8px;
  height: 8px;
}

//滚动条上滑块
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--mx-scrollbar-thumb-bg);
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: var(--mx-scrollbar-thumb-hover-bg);
}
