.modalWrapper {
  padding: 32px 42px;
}
.tips {
  font-size: 12px;
  .btn {
    color: var(--primary-color);
    cursor: pointer;

  }
}
