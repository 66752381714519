.expand_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  :global {
    .ant-select-single {
      height: 24px;
      padding:0 6px;
      
    }
    .ant-select-single .ant-select-selector {
      border-radius: 2px;
      color:#fff 
    }
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid rgba(0, 173, 255, 0.5);
      background: rgba(74, 144, 226, 0.16);
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
      color:#fff 
    }
  }
}

.dividerSty {
  width: 144px;
  height: 1px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.2);
}
.device_prop_wrapper {
  padding: 4px;
  .item_sty {
    // height: 28px;
    // height: 56px;
    height: 48px;
    width: auto !important;
    background-color: rgba(255,255,255,0.1);
    border-radius: 2px;
    margin: 4px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    position: relative;
    cursor: pointer;
    &:hover {
      background-color: #ffffff30
    }
    .label_sty {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      // will-change: transform;
      // -webkit-font-smoothing: antialiased;
      // text-rendering: optimizeLegibility
    }
    .value_sty {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
      width: 100%;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      // will-change: transform;
      // -webkit-font-smoothing: antialiased;
      // text-rendering: optimizeLegibility
    }
    .real_value_sty {
      font-size: 14px;
      color: #57FB8B;
      width: 100%;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      // will-change: transform;
      // -webkit-font-smoothing: antialiased;
      // text-rendering: optimizeLegibility
    }

  }
  .corner {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 0;
    height: 0;
    border: 6px solid #FAAD14;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  // .triangle::before {
  //   content: "";
  //   width:0;
  //   height: 0;
  //   border:8px solid transparent;
  //   border-right:8px solid #FAAD14;
  //   transform: rotate(135deg);
  //   position: absolute;
  //   right: -8px;
  //   top: -8px;
  //   cursor: pointer;
  // }
}

.expand_box {
  width: 160px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .nodeExpandIcon {
    color: rgba(255, 255, 255, 0.65);
  }
  &:hover {
    background-color: #ffffff20;
  }
}

.select_sty {
  margin-top: 4px;
}

.ele_drapDownSty {
  background-color: #1f1f1f;
  box-shadow: var(--mx-select-popup-shadow);
  border-radius: 2px;
  :global {
    .ant-select-item {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
    .ant-select-item.ant-select-item-option-active {
      color: #fff !important;
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
    .ant-select-item-option-disabled,
    .ant-select-item-option-disabled.ant-select-item-option-selected {
      background: none;
      color: var(--mx-select-disabled-color);
    }
  }
}

.ele_nodata_sty {
  color: rgba(255,255,255, 0.85);
}

@keyframes rotate {
  100% {
      transform: rotate(1turn);
  }
}

@keyframes ant-line {
  to {
    stroke-dashoffset: -1000;
  }
}



