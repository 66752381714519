.main_header {
  padding-top: 14px;
}
.main_color {
  color: var(--primary-color)
}
.header_text {
  color: rgba(255,255,255,0.65);
}
.table_box {
  padding: 10px 0;
}

.sticky_footer {
  position: sticky;
  left: 0;
  padding-left: 30px;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}
.container {
  // padding-left: 10px;
  .eachItemWrapper {
    width: 100%;
    height: auto;
    background: rgba(255,255,255,0.05);
    border-radius: 2px;
    border: 1px solid rgba(255,255,255,0.2);
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    .leftCol {
      width: 160px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .imgSty {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .nameSty {
        font-weight: 500;
        font-size: 14px;
        color: #4A90E2;
        line-height: 22px;
        font-style: normal;
        cursor: pointer;
      }
    }
    // .diliver {
    //   width: 1px;
    //   height: 100%;
    //   background: rgba(255,255,255,0.1);
    // }
    .rightCol {
      flex: 1;
      border-left: 1px solid rgba(255,255,255,0.1);
      padding-left: 8px;
      .labelSty {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(255,255,255,0.65);
        line-height: 22px;
        font-style: normal;
      }
      
      .rightCol_value {
        flex: 1;
        color: #4A90E2;
        .deviceName_Link{
          cursor: pointer;
        }
      }
      .rightCol_value_unit {
        color: rgba(255,255,255,0.85);
        line-height: 22px;
      }
      
      .dataPropertyContent {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        .dataPropertyContent_name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #4A90E2;
          cursor: pointer;
          line-height: 22px;
          font-style: normal;
          margin-right: 16px;
        }
      }
    }
  }
}