.wrapper {
  height: calc(100vh - 130px) !important;
  padding: 20px;
}

.fullWrapper {
  height: calc(100vh - 80px) !important;
  padding: 20px;
}

.screen_wrapper {
  height: 100%;
  width: 100%;
}

.page_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  .bottom_content {
    flex: 1;
    overflow: hidden;
    
    .inner_box {
      height: 100%;
      display: flex;
      .left_area {
        width: 480px;
        position: relative;
        transition: all .2s;
        .left_box {
          height: 100%;
          overflow: hidden;
          // overflow-y: auto;
          .left_box_inner {
            height: 100%;
            width: 480px;
          }
        }
      }
      .right_area {
        flex: 1;
        overflow: hidden;
        border: 1px solid rgba(255,255,255,0.2);
      }
    }
    
  }
}
.toggleBtn {
  cursor: pointer;
  position: absolute;
  z-index: 11;
  right: 0;
  top: 50%;
  margin-top: -80px;
  width: 20px;
  height: 66px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
}

