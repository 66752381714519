@import '../../common.module.scss';

// 通用开关
.customUniversalSwitchNode {
  height: 100%;
  width: 100%;
  color: #fff;

  .vline {
    position: absolute;
    top: 0px;
    left: 8px;
    width: 1px;
    height: 100%;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .hline {
    position: absolute;
    top: 72px;
    left: 9px;
    width: 30px;
    height: 1px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardMeterSty_wrapper {
    position: absolute;
    left: 39px;
    top: 20px;
    width: 160px;
    min-height: 102px;
    display: flex;
    flex-direction: column;
    
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid #ffcb47;
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    // z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }

    &:hover {
      outline: 2px solid #ffffff;
    }
    .cardMeterSty {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        .img_sty {
          margin: 0 auto;
          display: block;
        }
        .infoIcon {
          position: absolute;
          top: 8px;
          right: 8px;
          color: #FAAD14
        }
        
      }
      .operate {
        :global {
          .ant-btn {
            padding: 4px 0!important;
            font-size: 12px;
            display: flex;
            align-items: center;
          }
          
        }
        width: 100%;
        height: 28px;
        padding: 4px 8px 0px;
        margin-bottom: -4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .status_box {
          display: flex;
          justify-content: center;
          align-items: center;
          // 开闸样式
          .status_circle_open {
            width: 11px;
            height: 11px;
            border-radius: 6px;
            background: #E64242;
            box-shadow: 0px 2px 4px 0px #E64242;
            border: 1px solid #FFFFFF;
          }
          // 合闸样式
          .status_circle_close {
            width: 11px;
            height: 11px;
            border-radius: 6px;
            border: 1px solid #FFFFFF;
            background: #52C41A;
            box-shadow: 0px 2px 4px 0px #52C41A;
          }
          .status_text{
            margin-left: 4px;
            font-size: 12px;
            color: rgba(255,255,255,0.65);
            line-height: 20px;
          }
        }
      }
      .itemControlobjContentSty {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

}
.operate_switch_box {
  max-height: 200px;
  overflow-y: auto;
  background-color: #1F1F1F;
  border-radius: 8px;
  :global {
    .ant-dropdown-menu {
      background-color: #1F1F1F;
      color: rgba(255,255,255,0.65);
      .ant-dropdown-menu-item {
        &:hover,&:active {
          background: rgba(255,255,255,0.05);
        }
      }
    }
    
  }


  // 滚动条
  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(255,255,255,0.01);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.1);
    border-radius: 3px;
    
    &:hover {
     background-color: #a8a8a8;
    }
  
    &:active {
     background-color: #787878;
    }
  }
  

  
}