

.topCard {
  
  padding: 4px 2px 24px;
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(var(--mx-main-color),0.2);

  .itemWrap {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .divider {
    margin: 0 16px;
    height: 32px;
    width: 1px;
    background: var(--mx-light-border-color);
  }

  .label {
    color: var(--text-color-secondary);
  }

  .basIcon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
}
.card_bg {
  border-bottom: none;
  background: #2d2d2d;
  height: 64px;
  padding: 0 24px;

}

.title_filter_box {
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 15px;
}
