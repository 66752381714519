@import '../PowerGrid/index.module.scss';

.iconWrapper {
  padding: 8px;
  .iconBox {
    min-width: 160px !important;
    min-height: 82px !important;
    padding: 0 8px !important;

    .icon {
      margin-top: 5px;
      background-image: url(./backupBattery@2x.png) !important;
    }

    .line {
      width: 100%;
      height: 1px;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .controlArea {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 8px;

      .controlItem {
        display: flex;
        align-items: center;
        justify-content: center;
        // background: rgba(255, 255, 255, 0.1);
        background: #2D2D2D;
        border-radius: 2px;
        padding: 1px 8px;
        margin-bottom: 8px;
      }
    }
  }
}
