@import '../GridAccessPointDrawer/index.module.scss';

.img_sty {
  width: 40px;
  height: 40px;
}

.iconWrapper {
  min-height: 72px !important;

  .iconBoxMeters {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 160px;
    min-height: 82px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    flex-direction: row !important;
    min-width: 160px !important;
    min-height: 40px !important;

    .icon {
      width: 40px;
      height: 40px;
      background-size: 24px 24px !important;
      background-repeat: no-repeat;
      background-position: center;
    }

    .iconText {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      text-align: center;
      line-height: 22px;
      margin-top: 4px;
      margin: 0px 4px;
    }
  }
}