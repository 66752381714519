.topBtnSty {
  position: absolute;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.topTitle {
  color: var(--text-color);
  font-weight: bold;
  font-size: 18px;
}

.infoIconSty {
  color: #faad14;
  width: 14px;
  height: 14px;
  margin-left: 6px;
}

.topTags {
  margin-left: 60px;
  .hint {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  :global {
    .ant-space-item {
      line-height: 16px;
    }
  }
}

// .btnAndCanvasBgSty {
//   background: url('./imgs/bg.svg');
// }

.canvasWapper {
  margin-top: 50px;
  // border: 1px dashed rgba(74, 144, 226, 1);
}

.helloworldApp {
  display: flex;
  width: 100%;
  margin-top: -50px;
  font-family: sans-serif;
  // padding: 10px;

  .appContent {
    flex: 1;
    min-height: calc(100vh - 214px);
    // margin-right: 8px;
    // margin-left: 8px;
    border-radius: 5px;
    box-shadow: 0 12px 5px -10px rgb(0 0 0 / 10%), 0 0 4px 0 rgb(0 0 0 / 10%);
    body {
      min-width: 40px;
    }
    
  }
  
}

.layout {
  // max-height: calc(100vh - 128px);
  // height: calc(100% - 50px) !important;
  height: 100%;
  background-color: transparent;

  .siderSty {
    transition: all 0.2s ;
    background: rgb(45,45,45);
    // max-height: calc(100vh - 128px);
    // padding: 20px;
  }
}

.content {
  background: #232324;
  border-radius: 2px;
  position: relative;
  margin-left: 10px;

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .currentTagSty{
    position: absolute;
    right: 6px;
    top: 6px;
  }
}

.antLine :local {
  animation: antLine 30s infinite linear;
}

@keyframes antLine {
  to {
    stroke-dashoffset: -1000;
  }
}