@import '../../common.module.scss';
// 网关
.customGatewayReactNode {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 160px;
  width: 161px;
  height: 40px;
  color: #fff;
  border-radius: 2px;
  // background: rgba(255, 255, 255, 0.1);
  background: #2D2D2D;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 3px solid rgba(255, 146, 71, 1);
  outline: 1px solid rgba(255, 255, 255, 0.2);
  outline-offset: -1px;
  &:hover {
    outline: 2px solid #ffffff;
  }
  .gatewayNameSty {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
    margin-left: 8px;
    .itemControlobjContentSty {
      width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .deleteIcon {
    position: absolute;
    top: 0px;
    right: 4px;
    cursor: pointer;
    z-index: 99;
    color: rgba(255, 255, 255, 0.65);
    &:hover {
      color: #ffffff;
    }
  }
}
