@mixin tagStyle($background, $border, $color) {
  margin-left: 8px;
  border-radius: 2px;
  background: $background;
  border: $border;
  color: $color;
}



  .preview {
    width: 100%;
    height: auto;

    .previewBtn {
      padding: 0;
    }

    .iconWrapper {
      width: 100%;
      min-height: 114px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(../cover.png);
      background-position: center;
      opacity: 0.9;
      border: 1px solid rgba(255, 255, 255, 0.2);
      overflow: hidden;

      .iconBox {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 160px;
        min-height: 82px;
        // background: rgba(255, 255, 255, 0.1);
        background: #2D2D2D;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.2);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #16dd8e;
          border-radius: 2px 2px 0px 0px;
        }

        .icon {
          width: 40px;
          height: 40px;
          background-image: url(./FCS@2x.png);
          background-size: 40px 40px;
          background-repeat: no-repeat;
          background-position: center;
        }

        .iconText {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.85);
          text-align: center;
          line-height: 22px;
          margin-top: 4px;
          margin: 0px 4px;
        }
      }
    }
  }
  .iconWrapper {
    min-width: 496px !important;
    min-height: 72px !important;
  
    .iconBox {
      flex-direction: row !important;
      min-width: 160px !important;
      min-height: 40px !important;
      border-radius: 2px !important;
  
      &::after {
        width: 3px !important;
        height: 100% !important;
        left: 0 !important;
        top: 0 !important;
        background: #ff9247 !important;
        border-radius: 2px 0px 0px 2px !important;
      }
  
      .icon {
        background-image: url(./FCS@2x.png) !important;
        background-size: 24px 24px !important;
      }
  
      .iconText {
        min-height: 22px !important;
        font-size: 14px !important;
        line-height: 22px !important;
        margin-right: 10px !important;
      }
    }
  }
  

.title {
  width: 96%;
  display: flex;
  justify-content: space-between;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    .network {
      @include tagStyle(rgba(255, 146, 71, 0.1), 1px solid rgba(255, 146, 71, 0.5), #ff9247);
    }
    .measurement {
      @include tagStyle(rgba(255, 146, 71, 0.1), 1px solid rgba(255, 146, 71, 0.5), #ff9247);
    }
  }

  .editBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 5px;
    z-index: 9999;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #4a90e2;
  }
}
.form_box {
  padding: 0 48px;
}

.head_title {
  padding: 20px 0 15px 0;
}
.fcs_info_box {
  border: 1px solid rgba(255,255,255,0.2);
  padding: 10px;
}
.select_btn {
  color: var(--primary-color);
  cursor: pointer;
}
.disable_btn {
  color: rgba(255, 255, 255, 0.5);
  cursor: auto;
}
.action_line {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .click_text {
    color: var(--primary-color);
    cursor: pointer;
  }
}