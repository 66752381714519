
@import '../../common.module.scss';
// 防逆流装置
.customAntiBackFlowReactNode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 40px;
  color: #fff;
  opacity: 1;

  .vline {
    position: absolute;
    left: 0px;
    width: 1px;
    height: 100%;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .hline {
    position: absolute;
    top: 20px;
    left: 1px;
    width: 80px;
    height: 1px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardMeterSty {
    position: absolute;
    left: 80px;
    top: 0px;
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 3px solid #ffcb47;
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }
    &:hover {
      outline: 2px solid #ffffff;
    }
    .itemControlobjContentSty {
      width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 12px;
    }
  }
}
