@mixin tagStyle($background, $border, $color) {
  margin-left: 8px;
  border-radius: 2px;
  background: $background;
  border: $border;
  color: $color;
}

.modalWrapper {
  width: 100%;
  height: 100%;

  .preview {
    width: 100%;
    height: auto;

    .previewBtn {
      padding: 0;
    }

    .iconWrapper {
      width: 100%;
      min-height: 114px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(../cover.png);
      background-position: center;
      opacity: 0.9;
      border: 1px solid rgba(255, 255, 255, 0.2);
      overflow: hidden;

      .iconBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 160px;
        min-height: 82px;
        // background: rgba(255, 255, 255, 0.1);
        background: #2D2D2D;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.2);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background: #16dd8e;
          border-radius: 2px 2px 0px 0px;
        }

        .icon {
          width: 40px;
          height: 40px;
          background-image: url(./poweGrid@2x.png);
          background-size: 40px 40px;
          background-repeat: no-repeat;
          background-position: center;
        }

        .iconText {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.85);
          text-align: center;
          line-height: 22px;
          margin-top: 4px;
          margin: 0px 4px;
        }
      }
    }
  }
}

.circuitTitleSty{
  display: inline-block;
  width: 100px;
}

.itemCircuitSty {
  display: inline-block;
  width: 280px;
  text-align: left;
  padding: 0px 12px 12px 12px;
}

.jumpElecSty {
  color: #4A90E2;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.title {
  width: 96%;
  display: flex;
  justify-content: space-between;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);

    .source {
      @include tagStyle(rgba(22, 221, 142, 0.1), 1px solid rgba(22, 221, 142, 0.5), #16dd8e);
    }

    .load {
      @include tagStyle(rgba(171, 211, 53, 0.1), 1px solid rgba(171, 211, 53, 0.5), #abd335);
    }

    .storage {
      @include tagStyle(rgba(206, 144, 209, 0.1), 1px solid rgba(206, 144, 209, 0.5), #ce90d1);
    }
    .transformer {
      @include tagStyle(rgba(0, 173, 255, 0.1), 1px solid rgba(0, 173, 255, 0.5), #00adff);
    }
    .distribution {
      @include tagStyle(rgba(74, 144, 226, 0.1), 1px solid rgba(74, 144, 226, 0.5), #4a90e2);
    }
    .switch {
      @include tagStyle(rgba(255, 203, 71, 0.1), 1px solid rgba(255, 203, 71, 0.5), #ffcb47);
    }
    .measurement {
      @include tagStyle(rgba(255, 146, 71, 0.1), 1px solid rgba(255, 146, 71, 0.5), #ff9247);
    }
    .network {
      @include tagStyle(rgba(255, 146, 71, 0.1), 1px solid rgba(255, 146, 71, 0.5), #ff9247);
    }
    .other {
      @include tagStyle(#8CA9DC10, 1px solid #8CA9DC30, #8CA9DC);
    }
  }

  .editBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 5px;
    z-index: 9999;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #4a90e2;
  }
}
