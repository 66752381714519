
@import '../../common.module.scss';
// 计费点
.customBillingPointNode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 248px;
  height: 160px;
  color: #fff;

  .vline {
    position: absolute;
    left: 0px;
    width: 1px;
    height: 100%;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .hline {
    position: absolute;
    top: 80px;
    left: 1px;
    width: 85px;
    height: 1px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardMeterSty {
    // animation: animated-border 1.5s infinite;
    position: absolute;
    left: 85px;
    top: 0px;
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid #16DD8E;
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }
    &:hover {
      outline: 2px solid #ffffff;
    }

    .topContentSty{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .dividerSty {
      margin:8px;
      width: 144px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    .bottomContentSty{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .itemProp {
        width: 144px;
        height: 32px;
        background: rgba(255,255,255,0.1);
        border-radius: 2px;
        margin: 0px 8px 4px 8px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 5px 8px;
        .itemPropName{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(255,255,255,0.65);
          line-height: 22px;
        }
        .itemPropValue {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(255,255,255,0.85);
          line-height: 22px;
        }
      }
    }
  }
}
