@import '../../common.module.scss';
// 网关
.customGatewayReactNode {
  width: 100%;
  height: 100%;
  color: #fff;

  .cardMeterSty_wrapper {
    position: absolute;
    width: 160px;
    min-height: 40px;
    // height: 52px;
    // min-height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 3px solid rgba(255, 146, 71, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }

    &:hover {
      outline: 2px solid #ffffff;
    }


    .cardMeterSty {
      display: flex;
      flex-direction: column;
      align-items: center;
      .card_content {
        height: 38px;
        display: flex;
        align-items: center;
      }
      .gatewayNameSty {
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.85);
        line-height: 22px;
        margin-left: 8px;
        .itemControlobjContentSty {
          width: 120px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

  }
}
