.wrap {
  padding: 20px;
}

.formTitle {
  justify-content: flex-start;
  margin-bottom: 32px;

  .titleExtraContent {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.errorColor {
  color: var(--error-color);
}

.infoIcon {
  color: var(--warning-color);
  margin-right: 6px;
}

.transformerCard {
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 12px;
  background: #2d2d2d;
  border: 1px solid var(--mx-base-border-color);
  border-radius: 4px;

  .name {
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--mx-text-base-color);
    font-size: 14px;
  }

  .valueLabel {
    margin-left: auto;
    margin-right: 8px;
    white-space: nowrap;
    color: var(--mx-text-secondary-color);
    font-size: 12px;
  }

  .value {
    font-size: 12px;
    color: var(--primary-color);

    .unit {
      margin-left: 4px;
    }
  }
}

.mb0 {
  margin-bottom: 0 !important;
}

.longItem {
  :global {
    .ant-form-item-label {
      min-width: 148px;
      width: 148px;
    }
  }
}
