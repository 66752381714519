.wrap {
  padding: 20px;
}

.tipWrap {
  margin-bottom: 16px;
  color: var(--mx-text-desc-color);

  .tipIcon {
    margin-right: 5px;
    color: var(--warning-color);
  }
}
.text_error_color {
  color: var(--error-color)
}