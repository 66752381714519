@import '../../common.module.scss';

// 充电站
.customChargingStationReactNode {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 160px;
  height: 140px;
  color: #fff;

  .vline {
    position: absolute;
    top: 0px;
    left: 80px;
    width: 1px;
    height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardSty {
    position: absolute;
    top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 82px;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid rgba(171, 211, 53, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;
    &:hover {
      outline: 2px solid #ffffff;
    }
    .itemControlobjContentSty {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
      text-align: center;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .addCircle {
    position: absolute;
    left: 73px;
    top: 0px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .deleteIcon {
    position: absolute;
    top: 36px;
    right: 5px;
    cursor: pointer;
    z-index: 99;
    color: rgba(255, 255, 255, 0.65);
    &:hover {
      color: #ffffff;
    }
  }
}
