@import '../../common.module.scss';
// 变压器
.customTransFormerReactNode {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 160px;
  // width: 161px;
  height: 172px;
  color: #fff;
  .deleteIcon {
    position: absolute;
    top: 34px;
    right: 5px;
    cursor: pointer;
    z-index: 99;
    color: rgba(255, 255, 255, 0.65);
    &:hover {
      color: #ffffff;
    }
  }
  .labelSty {
    font-size: 16px;
  }

  .valueSty {
    font-size: 14px;
    color: rgba(250, 173, 20, 1);
  }

  .vlineTop {
    position: absolute;
    top: 0px;
    left: 80px;
    width: 1px;
    height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
  .vlineBottom {
    position: absolute;
    bottom: 0px;
    left: 80px;
    width: 1px;
    height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .addCircleTop {
    position: absolute;
    top: 0px;
    left: 73px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }
  .addCircleBottom {
    position: absolute;
    bottom: 0px;
    left: 73px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .cardSty {
    // animation: animated-border 1.5s infinite;
    position: absolute;
    top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    // height: 82px;
    height: 104px;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid rgba(0, 173, 255, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;
    &:hover {
      outline: 2px solid #ffffff;
    }
    .itemControlobjContentSty {
      text-align: center;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 6px;
    }
    .highVoltage {
      // position: absolute;
      // left: 6px;
      // top: 6px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #00ADFF;
      line-height: 20px;
    }
    .lowVoltage {
      position: absolute;
      left: 10px;
      top: 24px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a90e2;
      line-height: 20px;
    }
  }
}
