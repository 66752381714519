.formWrap {
  :global {
    .ant-form-item-label {
      width: fit-content !important;
      max-width: fit-content !important;
    }
  }
}

.tableFolding {
  :global {
    .ant-table-body {
      height: 0 !important;
    }
  }
}
