@import '../../common.module.scss';
.generatrixWrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;

  .generatrixNode {
    width: 280px;
    height: 4px;
    border: 1px solid #4a90e2;
    background: linear-gradient(to left, #4a90e2 0%, #4a90e2 50%, #fff 50%, #fff 100%);
    background-size: 24px 4px;
    background-repeat: repeat-x;
    position: relative;
  }

  .generatrixValueSty {
    width: 140px;
    height: 100%;
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .generatrixSty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 32px);
    height: 4px;

    margin: 0px 16px;

    border: 1px solid #4a90e2;
    background: linear-gradient(to left, #4a90e2 0%, #4a90e2 50%, #fff 50%, #fff 100%);
    background-size: 24px 4px;
    background-repeat: repeat-x;
  }

  .tline {
    position: absolute;
    left: 139px;
    top: -14px;
    width: 1px;
    height: 15px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
  .bline {
    position: absolute;
    left: 139px;
    top: 1px;
    width: 1px;
    height: 15px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
}
