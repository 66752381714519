.wrapper {
  padding: 20px;
  min-height: calc(100vh - 115px) !important;
}
.add-row-btn {
  width: 100%;
  .row-btn-inner {
    width: 100%;
  }
}
