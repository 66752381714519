@import '../../common.module.scss';

// 防逆流装置
.customAntiBackFlowReactNode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 144px;
  color: #fff;

  .addCircleTop {
    position: absolute;
    top: 0px;
    left: 1px;
    z-index: 99;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .addCircleBottom {
    position: absolute;
    bottom: 0px;
    left: 1px;
    z-index: 99;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .vline {
    position: absolute;
    left: 8px;
    width: 1px;
    height: 100%;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .hline {
    position: absolute;
    top: 72px;
    left: 9px;
    width: 30px;
    height: 1px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardMeterSty {
    position: absolute;
    left: 39px;
    top: 20px;
    width: 160px;
    height: 104px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid #ffcb47;
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;
    .icon {
      margin: 0 auto;
      display: block;
    }
    &:hover {
      outline: 2px solid #ffffff;
    }
    .itemControlobjContentSty {
    
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  // .cardMeterSty::after{
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: -20%;
  //   left: -100%;
  //   width: 150px;
  //   height: 50px;
  //   transform: rotate(-45deg);
  //   background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0));
  //   animation: cross 1.5s infinite;
  // }

  // @keyframes cross{
  //   from{

  //   }
  //   to{
      
  //     top: 80%;
  //     left: 100%;
  //     transform: rotate(-45deg);
  //   }
  // }



  .deleteIcon {
    position: absolute;
    top: 20px;
    right: 6px;
    cursor: pointer;
    z-index: 99;
    color: rgba(255, 255, 255, 0.65);
    &:hover {
      color: #ffffff;
    }
  }
}
