
.acBusBarWrapper {
  position: relative;
  display: flex;
  height: 22px;
  width: 1100px;
  opacity: 1;

  .generatrixEachNode {
    width: 1000px;
    height: 6px;
    border: 2px solid #4a90e2;
    background: linear-gradient(to left, #4a90e2 0%, #4a90e2 50%, #fff 50%, #fff 100%);
    background-size: 16px 6px;
    background-repeat: repeat-x;
    position: relative;
  }

  .generatrixValueSty {
    width: 140px;
    position: absolute;
    top: -10px;
    left: 965px;
  }

  .tline {
    position: absolute;
    left: 278px;
    top: -106px;
    width: 1px;
    height: 106px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .bline1 {
    position: absolute;
    left: 80px;
    top: 6px;
    width: 1px;
    height: 16px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
  .bline2 {
    position: absolute;
    right:190px;
    top: 6px;
    width: 1px;
    height: 16px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .otherBline1 {
    position: absolute;
    left: 80px;
    top: 6px;
    width: 1px;
    height: 16px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
  .otherBline2 {
    position: absolute;
    left: 278px;
    top: 6px;
    width: 1px;
    height: 16px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
  .otherBline3 {
    position: absolute;
    left: 476px;
    top: 6px;
    width: 1px;
    height: 16px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
}
