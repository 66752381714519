.highlight {
  color: #FAAD14
}
.listCon {
  display: flex;
  flex-direction: column;
  flex: 1;
  :global {
    .mx-custom-filter {
      background-color: var(--component-background);
      padding: 0 24px;
      margin-bottom: 10px;
    }
  }

  .table_box {
    background-color: var(--component-background);
    padding: 20px;
    flex: 1;
  }
}
