.contentWrap {
  padding: 12px;
  height: 100%;
  overflow: auto;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.calendar {
  height: 100%;
  overflow: auto;
}

.dateHeader {
  display: flex;
  gap: 8px;

  .datePicker {
    margin-left: auto;
  }
}

.dateCell {
  min-height: 78px;
  border: 1px solid #49494a;
  position: relative;

  &:hover {
    border-color: #00adff;
  }

  .cellContent {
    display: flex;
    height: 100%;
    padding: 7px 12px 0 4px;
    position: relative;
  }

  .statusTag {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }

  .dayNumber {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .currentDayNumber {
    background: rgba(74, 144, 226, 0.5);
  }
}

.disabledSelect {
  background: rgba(255, 255, 255, 0.05) !important;
  cursor: not-allowed !important;
}

.viewing:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #00adff;
}

.viewing {
  .dayNumber {
    color: var(--primary-color);
  }
}

.selected {
  border: 1px solid #00adff;
  background: rgba(74, 144, 226, 0.25);
}

.haveRun {
  background: rgba(74, 144, 226, 0.15);

  &.viewing:before {
  }
}

.running {
  background: rgba(82, 196, 26, 0.15);

  &.viewing:before {
    background: #52c41a;
  }
}

.haveStop {
  background: rgba(230, 66, 66, 0.15);

  &.viewing:before {
    background: #e64242;
  }
}

.toRun {
  background: rgba(255, 255, 255, 0.15);

  &.viewing:before {
    background: rgba(255, 255, 255, 0.45);
  }
}

.checkIcon {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
}
