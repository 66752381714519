@import '../../common.module.scss';
// 其他-垂直
.otherVerticalNode {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 160px;
  height: 172px;
  // height: 100%;
  color: #fff;
  position: relative;
  
  .labelSty {
    font-size: 16px;
  }

  .valueSty {
    font-size: 14px;
    color: rgba(250, 173, 20, 1);
  }

  .vlineTop {
    // position: absolute;
    // top: 0px;
    // left: 80px;
    width: 1px;
    margin-left: 1px;
    flex: 1;
    // height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }
  .vlineBottom {
    // position: absolute;
    // bottom: 0px;
    // left: 80px;
    width: 1px;
    margin-left: 1px;
    flex: 1;
    // height: 38px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .content {
    // position: absolute;
    // top: 34px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    // min-height: 116px;
    min-height: 102px;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid #8CA9DC;
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }

    &:hover {
      outline: 2px solid #ffffff;
    }
  }

  .cardSty {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    .top_card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .infoIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      color: #FAAD14
    }
    .itemControlobjContentSty {
      text-align: center;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 6px;
    }
    .highVoltage {
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #00ADFF;
      line-height: 20px;
    }
    .lowVoltage {
      position: absolute;
      left: 10px;
      top: 24px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a90e2;
      line-height: 20px;
    }
  }
}
