
@import '../../common.module.scss';


// 交直流变换器
.customAcDcConverterNode {
  width: 344px;
  height: 106px;
  color: #fff;
  opacity: 1;
  position: relative;
  .labelSty {
    font-size: 16px;
  }

  .valueSty {
    font-size: 14px;
    color: rgba(250, 173, 20, 1);
  }

  .vlineBottom {
    position: absolute;
    bottom: 0px;
    left: 80px;
    width: 1px;
    height: 24px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardSty {
    // animation: animated-border 1.5s infinite;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 344px;
    height: 82px;
    border-radius: 2px;
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid rgba(0, 173, 255, 1);
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;

    z-index: 0;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 4s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 1px;
      top: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #2D2D2D;
      border-radius: 2px;
    }
    &:hover {
      outline: 2px solid #ffffff;
    }
    .leftContentSty{
      width: 118px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .nameSty {
        text-align: center;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        // overflow: hidden;
      }
    }

    .dividerSty {
      margin: 9px 9px 8px 0px;
      width: 1px;
      height: 65px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    .rightContentSty{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      .itemProp {
        width: 68px;
        height: 66px;
        background: rgba(255,255,255,0.1);
        border-radius: 2px;
        margin: 5px 2px 8px 2px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding: 7px;
        .itemPropName{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(255,255,255,0.65);
          line-height: 22px;
        }
        .itemPropValue {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(255,255,255,0.85);
          line-height: 22px;
        }
      }
    }

  }
}
