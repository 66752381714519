@import '../../common.module.scss';
// 电网侧
.customReactNode {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 160px;
  // width: 161px;
  height: 120px;
  color: #fff;
  position: relative;
  .deleteIcon {
    position: absolute;
    right: 0px;
  }

  .labelSty {
    font-size: 16px;
  }

  .valueSty {
    font-size: 14px;
    color: rgba(250, 173, 20, 1);
  }

  .hline {
    width: 100%;
    height: 1px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .vline {
    width: 1px;
    position: absolute;
    left: 80px;
    bottom: 0px;
    height: 34px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .addCircle {
    position: absolute;
    left: 73px;
    bottom: 0px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .cardSty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 82px;
    border-radius: 2px;
    background-color: rgba(74, 144, 226, 0.25);
    border: 1px solid rgba(74, 144, 226, 0.5);
    &:hover {
      border: 2px solid #ffffff;
    }
  }
}
