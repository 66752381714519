.modalContent {
  display: flex;
  height: 580px;

  .left {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 100%;

    .checkListWrap {
      flex: 1;
      margin-top: 16px;
      overflow: auto;
    }
  }

  .divider {
    width: 1px;
    height: 100%;
    margin: 0 15px;
    background-color: var(--mx-base-border-color);
  }

  .right {
    flex: 1;
    position: relative;

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      color: var(--text-color-secondary);
    }
  }
}
