// 电网侧
.customReactNode {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 160px;
    height: 40px;
    color: #fff;
    position: relative;
    opacity: 1;

    .labelSty {
        font-size: 16px;
    }

    .valueSty {
        font-size: 14px;
        color: rgba(250, 173, 20, 1);
    }

    .hline {
        width: 100%;
        height: 1px;
        background-color: rgba(74, 144, 226, 1);
        border: 1px solid rgba(74, 144, 226, 1);
    }

    .vline {
        width: 1px;
        position: absolute;
        left: 80px;
        bottom: 0px;
        height: 14px;
        background-color: rgba(74, 144, 226, 1);
        border: 1px solid rgba(74, 144, 226, 1);
    }

    .cardSty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 82px;
        border-radius: 2px;
        background-color: rgba(74, 144, 226, 0.25);
        border: 1px solid rgba(74, 144, 226, 0.5);

        &:hover {
            border: 2px solid #ffffff;
        }
    }
}