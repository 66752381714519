@import '../PowerGrid/index.module.scss';

.iconBox {
  position: relative !important;
  min-height: 104px !important;

  &::after {
    background: #00adff !important;
  }

  .icon {
    background-image: url(./transformer@2x.png) !important;
  }
  .highVoltage {
    // position: absolute;
    // left: 6px;
    // top: 6px;
    height: 20px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #00ADFF;
    line-height: 20px;
  }

  .lowVoltage {
    position: absolute;
    left: 8px;
    top: 32px;
    height: 20px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #4a90e2;
    line-height: 20px;
  }
}
