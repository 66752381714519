
.topo_box {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  .appContent {
    flex: 1;
    // min-height: calc(100vh - 228px);
    // margin-right: 8px;
    // margin-left: 8px;
    border-radius: 5px;
    box-shadow: 0 12px 5px -10px rgb(0 0 0 / 10%), 0 0 4px 0 rgb(0 0 0 / 10%);
    body {
      min-width: 40px;
    }
    
  }
  
}