.contentWrap {
  padding: 12px;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  overflow: hidden;
}

.flexContent {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overflowContent {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.strategyDetailWrap {
  height: 100%;
  position: relative;
  overflow: hidden;

  :global {
    .mx-title-wrapper {
      height: 32px;
    }
  }
}

.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.actionView {
  height: 100%;
  overflow: hidden;
  color: var(--text-color-secondary);

  :global {
    .ant-form-item-label {
      max-width: fit-content !important;
    }
  }

  .monthRow {
    display: flex;
    margin-bottom: 16px;

    .monthLabel {
      color: var(--primary-color);
      white-space: nowrap;
    }

    .dateListWrap {
      display: flex;
      flex-wrap: wrap;
    }

    .dateTag {
      display: flex;
      align-items: center;
      width: 116px;
      margin-bottom: 6px;
      background: rgba(74, 144, 226, 0.19);

      .closeIcon {
        margin-left: auto;
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  }
}
