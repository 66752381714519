@mixin tagStyle($background, $border, $color) {
  margin-left: 8px;
  border-radius: 2px;
  background: $background;
  border: $border;
  color: $color;
}


.title {
  width: 96%;
  display: flex;
  justify-content: space-between;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);

    .source {
      @include tagStyle(rgba(22, 221, 142, 0.1), 1px solid rgba(22, 221, 142, 0.5), #16dd8e);
    }

    .load {
      @include tagStyle(rgba(171, 211, 53, 0.1), 1px solid rgba(171, 211, 53, 0.5), #abd335);
    }

    .storage {
      @include tagStyle(rgba(206, 144, 209, 0.1), 1px solid rgba(206, 144, 209, 0.5), #ce90d1);
    }
    .transformer {
      @include tagStyle(rgba(0, 173, 255, 0.1), 1px solid rgba(0, 173, 255, 0.5), #00adff);
    }
    .distribution {
      @include tagStyle(rgba(74, 144, 226, 0.1), 1px solid rgba(74, 144, 226, 0.5), #4a90e2);
    }
    .switch {
      @include tagStyle(rgba(255, 203, 71, 0.1), 1px solid rgba(255, 203, 71, 0.5), #ffcb47);
    }
    .measurement {
      @include tagStyle(rgba(255, 146, 71, 0.1), 1px solid rgba(255, 146, 71, 0.5), #ff9247);
    }
    .network {
      @include tagStyle(rgba(255, 146, 71, 0.1), 1px solid rgba(255, 146, 71, 0.5), #ff9247);
    }
    .other {
      @include tagStyle(#8CA9DC10, 1px solid #8CA9DC30, #8CA9DC);
    }
  }

}