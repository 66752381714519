.addTopologyBtn {
  margin: 8px 0 16px 10px;
}

.modalWrapper {
  padding: 32px 42px;
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 0 10px;

  .content {
    position: relative;
    width: 100%;
    min-height: 200px;
    padding: 16px;
    background: rgba(74, 144, 226, 0.1);
    border-radius: 2px;

    .statusTipsSty{
      position: absolute;
      bottom: 16px;
      left: 16px;
      width: 100%;
      line-height: 32px;
      font-weight: 400;
      color: rgba(255,255,255,0.45);
    }

    .action {
      position: absolute;
      bottom: 16px;
      right: 16px;
      width: 100%;
      text-align: right;
    }

    :global {
      .ant-descriptions-item-content {
        font-size: 16px;
        margin-top: -2px;
        margin-bottom: 6px;
      }

      .ant-descriptions .ant-descriptions-row > td {
        padding-bottom: 0;
      }

      .ant-descriptions-row > th {
        padding-bottom: 0;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #4a90e2;
    }
  }
}
