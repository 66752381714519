@import '../../common.module.scss';

// 其他样式-水平
.otherTransverseNode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 80px;
  color: #fff;

  .addCircleTop {
    position: absolute;
    top: 0px;
    left: 1px;
    z-index: 99;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .addCircleBottom {
    position: absolute;
    bottom: 0px;
    left: 1px;
    z-index: 99;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: rgba(74, 144, 226, 1);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .addIcon {
      margin-top: -1px;
      font-size: 15px;
    }
    &:hover {
      background-color: #75b3f0;
    }
  }

  .vline {
    position: absolute;
    left: 8px;
    width: 1px;
    height: 100%;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .hline {
    position: absolute;
    top: 40px;
    left: 9px;
    width: 30px;
    height: 1px;
    background-color: rgba(74, 144, 226, 1);
    border: 1px solid rgba(74, 144, 226, 1);
  }

  .cardMeterSty {
    // animation: animated-border 1.5s infinite;
    position: absolute;
    left: 39px;
    top: 20px;
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    // background: rgba(255, 255, 255, 0.1);
    background: #2D2D2D;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 3px solid #8CA9DC;
    outline: 1px solid rgba(255, 255, 255, 0.2);
    outline-offset: -1px;
    &:hover {
      outline: 2px solid #ffffff;
    }
    .itemControlobjContentSty {
      width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .deleteIcon {
    position: absolute;
    top: 20px;
    right: 6px;
    cursor: pointer;
    z-index: 99;
    color: rgba(255, 255, 255, 0.65);
    &:hover {
      color: #ffffff;
    }
  }
}
